import React from 'react';
import { useMutation } from '@apollo/client';
import {
  CompositeNavigationProp,
  useNavigation,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { useAuth } from '~/context/auth';
import { useIntercom } from '~/context/intercom';
import useAuthTeams from '~/data/hooks/useAuthTeams';
import useLikePost from '~/data/hooks/useLikePost';
import usePollVote from '~/data/hooks/usePollVote';
import usePolls from '~/data/hooks/usePolls';
import { BasePoll, Post } from '~/data/models/post';
import {
  BLOCK_USER,
  REPORT_MUTATION,
  REPORT_POST_CONTENT,
} from '~/data/operations/post';
import { PlanningStackParamList, RootStackParamList } from '~/navigation/types';
import { t } from '~/utils/i18n';
import PollsLayout from './layout';

type PollsNavProp = CompositeNavigationProp<
  StackNavigationProp<PlanningStackParamList, 'Polls'>,
  StackNavigationProp<RootStackParamList>
>;
export default function Polls(props?: { now?: Date }): JSX.Element {
  const navigation = useNavigation<PollsNavProp>();

  const { authUserId, hiddenPostIds, onHidePost } = useAuth();
  const { onHelpCenter } = useIntercom();
  const { authTeamIds } = useAuthTeams();

  const {
    polls,
    loading: pollsLoading,
    hasNextPage: pollsHasNextPage,
    onRefresh: onRefreshPolls,
    onLoadMore: onLoadMorePolls,
    onDeletePoll,
  } = usePolls();

  const { onLikePost } = useLikePost();
  const { onPollVote } = usePollVote();
  const [reportPostContent] = useMutation(REPORT_POST_CONTENT);
  const [blockUser] = useMutation(BLOCK_USER);
  const [reportMutation] = useMutation(REPORT_MUTATION);

  const pollPosts: Post[] = polls
    .filter((poll) => poll.post)
    .map((poll) => ({
      ...(poll.post as Post),
      poll: poll as BasePoll,
    }));

  const onReportPost = async (postId: string) => {
    try {
      await reportPostContent({
        variables: {
          input: {
            id: postId,
          },
        },
      });
      Snackbar.show(t('g.postReported'));
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onBlockUser = async (userGroupId: string, teamId?: string) => {
    try {
      await blockUser({
        variables: {
          input: {
            id: teamId || userGroupId,
          },
        },
      });
      teamId
        ? Snackbar.show(t('g.teamBlocked'))
        : Snackbar.show(t('g.userBlocked'));
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onReportUser = async (userGroupId: string, teamId?: string) => {
    try {
      await reportMutation({
        variables: {
          input: {
            id: teamId || userGroupId,
          },
        },
      });
      teamId
        ? Snackbar.show(t('g.teamReported'))
        : Snackbar.show(t('g.userReported'));
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const mappedPosts = pollPosts.map((post) => ({
    ...post,
    hidden: hiddenPostIds.includes(post.id),
  }));

  const openBannersInBrowser = (url: string) => {
    navigation.navigate('Browser', {
      title: 'Banner',
      url,
    });
  };
  return (
    <PollsLayout
      onHelp={onHelpCenter}
      now={props?.now}
      loading={pollsLoading}
      posts={mappedPosts}
      authUserId={authUserId}
      pollsHasNextPage={pollsHasNextPage}
      authTeamIds={authTeamIds}
      openBannersInBrowser={openBannersInBrowser}
      onBack={() => navigation.goBack()}
      onPollVote={onPollVote}
      onCreatePoll={() => navigation.navigate('CreatePoll')}
      onLike={onLikePost}
      onComment={(postId) => navigation.navigate('Comments', { postId })}
      onDelete={(postId, pollId) => pollId && onDeletePoll(pollId)}
      onReportPost={onReportPost}
      onReportUser={onReportUser}
      onBlockUser={onBlockUser}
      onHidePost={onHidePost}
      onRefresh={onRefreshPolls}
      onLoadMorePolls={onLoadMorePolls}
      onEdit={(postId) => navigation.navigate('EditPoll', { postId })}
    />
  );
}
