import styled from 'styled-components/native';
import Icon from '~/components/Icon';
import ImageComp from '~/components/Image';
import { TAB_SCREEN_CONTENT_WIDTH } from '~/data/constants';
import IconStyled from '../IconStyled';

const IMAGE_WIDTH = TAB_SCREEN_CONTENT_WIDTH - 16 * 2;

export const Container = styled.View`
  background-color: ${({ theme }) => theme.color.base.c0};
  padding: 16px;
  border-radius: 5px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
  margin: 0 16px 8px;
`;

export const Header = styled.View`
  flex-direction: row;
`;

export const HeaderContent = styled.View`
  flex: 1;
  padding-horizontal: 8px;
`;

export const TeamTitle = styled.Text`
  color: ${({ theme }) => theme.color.base.c10};
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  font-size: 16px;
  line-height: 20px;
`;

export const Created = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 12px;
  line-height: 20px;
`;

export const OptionsIcon = styled(Icon).attrs(({ theme }) => ({
  name: 'dots-horizontal',
  size: 24,
  color: theme.color.base.c7,
}))``;

export const Content = styled.View``;

export const Text = styled.Text`
  padding-vertical: 24px;
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c10};
  font-size: 16px;
  line-height: 20px;
`;

export const HiddenText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 16px;
  line-height: 20px;
`;

export const TouchableScalableImage = styled.TouchableOpacity.attrs({
  activeOpacity: 0.9,
})``;

export const ImageContainer = styled.View`
  flex-direction: row;
`;

export const ScalableImage = styled(ImageComp).attrs({
  autoHeightFromWidth: IMAGE_WIDTH,
  autoHeightMax: IMAGE_WIDTH,
})`
  background-color: ${({ theme }) => theme.color.base.c1};
  border-radius: 14px;
  width: ${IMAGE_WIDTH}px;
  margin-bottom: 24px;
`;

interface ITouchableImage {
  last: boolean;
}

export const TouchableImage = styled.TouchableOpacity.attrs({
  activeOpacity: 0.9,
})<ITouchableImage>`
  flex: 1;
  padding-right: ${({ last }) => (last ? 0 : 8)}px;
  padding-bottom: 24px;
`;

export const Image = styled(ImageComp)`
  background-color: ${({ theme }) => theme.color.base.c1};
  border-radius: 14px;
  aspect-ratio: 1;
`;

export const CountInfoWrapper = styled.TouchableOpacity.attrs({
  activeOpacity: 0.9,
})``;

interface ICountInfo {
  liked?: boolean;
  view?: string;
}
export const CountInfo = styled.Text<ICountInfo>`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  font-size: 14px;
  color: ${({ liked, theme }) =>
    liked ? theme.color.error : theme.color.base.c6};
  padding-right: 8px;
  margin-left: ${({ view }) => (view === 'comments' ? 8 : 0)}px;
`;

export const CountValue = styled(CountInfo)`
  font-family: ${({ theme }) => theme.fontFamily.core.w600};
  padding-right: 0;
`;

export const DeadlineRow = styled.View`
  flex-direction: row;
  padding-bottom: 24px;
`;

export const CountRow = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const ClockIcon = styled(Icon).attrs(({ theme }) => ({
  name: 'clock',
  size: 16,
  color: theme.color.base.c5,
}))`
  margin-right: 8px;
`;

export const Footer = styled.View`
  margin-top: 16px;
  flex-direction: row;
`;

interface IFooterCol {
  rightBorder?: boolean;
}
export const FooterCol = styled.View<IFooterCol>`
  flex: 1;
  height: 32px;
  align-items: center;
  justify-content: center;
  border-right-width: ${({ rightBorder = true }) => (rightBorder ? 1 : 0)}px;
  border-color: ${({ theme }) => theme.color.base.c4};
  padding-horizontal: 16px;
`;

interface IActionButton {
  liked?: boolean;
}
export const ActionButton = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})<IActionButton>`
  padding: 2px 4px;
  border-radius: 5px;
  flex-direction: row;
  align-items: center;
  ${({ liked, theme }) => liked && `background-color: ${theme.color.base.c0}`}
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1.0)};
`;

export const ActionButtonText = styled.Text<IActionButton>`
  padding-left: 8px;
  font-family: ${({ liked, theme }) =>
    liked ? theme.fontFamily.core.w600 : theme.fontFamily.core.w400};
  color: ${({ liked, theme }) => (liked ? '#FF2900' : theme.color.base.c7)};
  font-size: 16px;
  line-height: 20px;
`;

export const PollContent = styled.View`
  padding-vertical: 24px;
`;

export const TOOLTIP_BUTTON_HEIGHT = 36;

interface ITooltipButton {
  hasBorderBottom?: boolean;
}
export const TooltipButton = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})<ITooltipButton>`
  height: ${TOOLTIP_BUTTON_HEIGHT}px;
  padding-horizontal: 16px;
  justify-content: center;
  border-bottom-width: ${({ hasBorderBottom = true }) =>
    hasBorderBottom ? 1 : 0}px;
  border-color: ${({ theme }) => theme.color.brand_02};
`;

interface ITooltipButtonText {
  warning?: boolean;
}
export const TooltipButtonText = styled.Text<ITooltipButtonText>`
  font-family: ${({ theme }) => theme.fontFamily.core.w400};
  color: ${({ warning, theme }) =>
    warning ? theme.color.error : theme.color.base.c9};
  font-size: 16px;
  line-height: 20px;
`;

export const CommentStyledIcon = styled(IconStyled).attrs(({ theme }) => ({
  name: 'comment',
  color: theme.color.base.c7,
  size: 16,
}))``;

export const ShareStyledIcon = styled(IconStyled).attrs(({ theme }) => ({
  name: 'share',
  color: theme.color.base.c7,
  size: 16,
}))``;
