export const getNow = () => new Date();

/*
//for easily mocking on jest
jest.mock('~/utils/dates/now', () => ({
  getNow: jest
    .fn()
    .mockReturnValue(new Date('2024-05-22T21:12:55.095000+00:00')),
}));
*/
